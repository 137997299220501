@import 'variables';
@import 'mixins';

.field {
  margin-bottom: 30px;
}

.hints-and-errors {
  text-align: right;
  margin: 2px 0 -16px;

  &,
  small {
    height: 14px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }

  .error {
    color: $error-red;
  }

  .hint {
    color: $light-medium-grey;
  }
}

.label {
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;

  margin-bottom: 10px;
}

.required {
  &::after {
    content: '*';
    color: $ems-red;
    margin-left: 1px;
  }
}

.control {
}

textarea {
  resize: vertical;
}

.input:not(.plain-input) {
  @extend %input;

  &.textarea {
    resize: none;
    height: 100%;
  }

  &.input-radio {
    display: flex;
    cursor: pointer;
    border: none;
    background: transparent;
    padding-left: 0;
    padding-right: 0;

    @include breakpoint(toad) {
      margin-bottom: 10px;

      &.input-radio-last {
        margin-bottom: 0;
      }
    }

    input[type='radio'] {
      margin-right: 9px;
      margin-left: 3px;
      transform: scale(1.8);
      align-self: center;
      width: auto;
      cursor: pointer;
    }
  }

  &[type='checkbox'] {
    width: auto;
    margin-right: 5px;
  }
}

.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.checkbox-label {
  display: flex;
  align-items: center;

  input[type='checkbox'] {
    margin-right: 12px;
  }
}
