@use 'sass:math';

$max-width: 1400px;
$ems-blue: #2d479e;
$ems-red: #c03439;

$red: #c15252;
$blue: #3b60a7;
$yellow: #9b823f;

$warn-yellow: #c1ad64;
$success-green: #75a976;
$error-red: red;

$text-grey: #494c57;

$dark-grey: #32343c;
$medium-grey: #383b44;
$middle-grey: #888888;

$light-medium-grey: #acaeb6;
$light-grey: #d0d1d5;
$lightest-grey: #f1f1f1;

$white: white;

// $super-mario: 1400px+;
$mario-width: 1400px;
$luigi-width: 1080px; // Chrome scrollbar?
$peach-width: 780px;
$toad-width: 540px;

// grid overrides

$xs: 'toad';
$sm: 'peach';
$md: 'luigi';
$lg: 'mario';
$default: '';

$member-header-height: 75px;

$gutter-width: 20px;
$outer-margin: 20px;

// ` / 1px` to remove units
$width-xs: math.div($toad-width, 1px);
$width-sm: math.div($peach-width, 1px);
$width-md: math.div($luigi-width, 1px);
$width-lg: math.div($mario-width, 1px);

$viewport-xs: #{$width-xs}px;
$viewport-sm: #{$width-sm}px;
$viewport-md: #{$width-md}px;
$viewport-lg: #{$width-lg}px;

$defaultQuery: default;

$sizes:
  #{$default},
  #{$lg},
  #{$md},
  #{$sm},
  #{$xs};
$queries: (
  #{$default}: default,
  #{$lg}: #{$viewport-lg},
  #{$md}: #{$viewport-md},
  #{$sm}: #{$viewport-sm},
  #{$xs}: #{$viewport-xs}
); // end grid overrides
