@import 'variables';

@mixin breakpoint($point) {
  @if ($point == super-mario) {
    @media (min-width: $mario-width) {
      @content;
    }
  } @else if ($point == mario) {
    @media (max-width: $mario-width) {
      @content;
    }
  } @else if ($point == luigi) {
    @media (max-width: $luigi-width) {
      @content;
    }
  } @else if ($point == peach) {
    @media (max-width: $peach-width) {
      @content;
    }
  } @else if ($point == toad) {
    @media (max-width: $toad-width) {
      @content;
    }
  } @else {
    @media (max-width: $point) {
      @content;
    }
  }
}

@mixin above-breakpoint($point) {
  @if ($point == mario) {
    @media (min-width: $mario-width) {
      @content;
    }
  } @else if ($point == luigi) {
    @media (min-width: $luigi-width) {
      @content;
    }
  } @else if ($point == peach) {
    @media (min-width: $peach-width) {
      @content;
    }
  } @else if ($point == toad) {
    @media (min-width: $toad-width) {
      @content;
    }
  } @else {
    @media (min-width: $point) {
      @content;
    }
  }
}

%container {
  display: block;
  max-width: $max-width + 40px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;

  h1 {
    margin-top: 0;
  }

  @include breakpoint(380px) {
    padding: 0 20px;
  }
}

@mixin container() {
  //@extend %container;
  display: block;
  max-width: $max-width + 40px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;

  @include breakpoint(380px) {
    padding: 0 20px;
  }
}

@mixin input-container-errors($selector) {
  #{$selector}.ng-invalid.ng-touched .input,
  .ng-submitted #{$selector}.ng-invalid .input {
    @extend %input-errors;
  }
}

@mixin button($color) {
  background: $color;
  border-color: $color;

  &:hover {
    background: lighten($color, 6%);
    border-color: lighten($color, 6%);
    color: white;

    &.btn-outline {
      color: lighten($color, 6%);
    }
  }

  &:active {
    background: lighten($color, 13%);
    border-color: lighten($color, 13%);
    color: white;

    &.btn-outline {
      color: lighten($color, 13%);
    }
  }

  &:focus {
    border-color: darken($color, 18%);
  }

  &.btn-outline {
    color: $color;
  }

  &.btn-inverse {
    color: $color;
    background: white;
    border-color: white;

    &:hover,
    &:active {
      color: lighten($color, 6%);
      background: white;
      border-color: white;
    }

    &:focus {
      background: white;
      border-color: darken($color, 18%);
    }
  }

  &.btn-text {
    color: $color;

    &:hover,
    &:active {
      color: lighten($color, 6%);
    }

    &:focus {
      text-decoration: underline;
    }
  }
}

%input-errors {
  border-color: red;

  &:focus {
    border-color: $light-grey;
  }
}

%vertical-filler {
  display: flex;
  flex-direction: column;
  height: 100%;

  > .fill-vertical-space {
    flex-grow: 1;
  }
}

%hide-super-mario {
  @include breakpoint(super-mario) {
    display: none;
  }
}

%hide-mario {
  @include breakpoint(mario) {
    display: none;
  }
}

%hide-luigi {
  @include breakpoint(luigi) {
    display: none;
  }
}

%hide-peach {
  @include breakpoint(peach) {
    display: none;
  }
}

%hide-toad {
  @include breakpoint(toad) {
    display: none;
  }
}

%input {
  display: block;
  box-shadow: none;
  outline: none;

  border: 2px solid $light-medium-grey;
  background-color: $lightest-grey;
  font-size: 20px;
  line-height: 28px;
  padding: 16px;
  width: 100%;

  .smaller-input &,
  &.smaller-input {
    padding: 9px;
  }

  &:focus,
  &.focused {
    border-color: $light-grey;
  }

  &.ng-invalid.ng-touched,
  .ng-submitted &.ng-invalid {
    @extend %input-errors;
  }

  &:read-only:not(.faux-writable):not(label):not(input[type='radio']),
  &:disabled,
  &.disabled,
  &.readonly {
    cursor: not-allowed !important;
    background-color: $light-medium-grey;
    border-color: $medium-grey;
  }
}
